export const ORDER_BY = {
  mostViewed: "MOST_VIEWED",
  reviewScore: "REVIEW_SCORE",
  price: "PRICE",
  discount: "DISCOUNT",
};

export const SORT_DIRECTION = {
  ascending: "ASCENDING",
  descending: "DESCENDING",
};
