import { PRODUCT_TOKEN, PRODUCT_URL } from "../endpoints";
import type { Slice } from "./types";

const BASE_URL =
  typeof window === "undefined"
    ? PRODUCT_URL
    : `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;

const VALUES_DENYLIST: (string | number | boolean | number[] | string[])[] = [
  "prijsdalers",
];

export const resolver = {
  list: async (
    variables: {
      [key: string]:
        | string
        | number
        | number[]
        | boolean
        | string[]
        | undefined;
      slug?: string;
    } = {}
  ): Promise<Slice[]> => {
    const url = new URL(`/v1/slices`, BASE_URL);
    const filter = { ...variables };

    Object.keys(filter).forEach((key) => {
      const value = filter[key];

      if (value === undefined || value === "") return;
      if (VALUES_DENYLIST.includes(value)) return;

      if (Array.isArray(value)) {
        if (value.filter((v) => v === "").length > 0) return;

        value.forEach((val) => url.searchParams.append(key, val.toString()));
      } else if (value) {
        if (value === "") return;

        url.searchParams.append(key, value.toString());
      }
    });

    return fetch(url, {
      headers: { authorization: PRODUCT_TOKEN },
      cache: "no-store",
    })
      .then(async (res) =>
        res.status === 200
          ? ((await res.json()) as Promise<Slice[]>)
          : ([] as Slice[])
      )
      .catch((e) => [] as Slice[]);
  },
};
