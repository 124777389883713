"use client";
import { resolver as slicesResolver } from "api/slices";
import { SORT_DIRECTION } from "api/products/constants";
import type { Slice, SliceRule } from "api/slices/types";
import {
  cn,
  getSlugWithoutBrand,
  sanitizeCategorySlug,
  sanitizeFilter,
  toUppercase,
} from "../../utils";
import { Button, buttonVariants } from "../atoms/button";
import { Icon } from "../atoms/icon/icon";
import { ListingFilterItem } from "../molecules/listing-filter-item";
import { useEffect, useRef, useState } from "react";
import { $totalProducts, setFilters as setStoreFilters } from "stores/filters";
import { useMedia } from "../../hooks/use-media";
import { useStore } from "@nanostores/react";

interface CompareListingFiltersProps {
  filter: Record<
    string,
    string | number | boolean | number[] | string[] | undefined
  >;
  initialSlices: Slice[];
  className?: string;
}

const SORT_MAP: Record<string, string> = {
  MOST_VIEWED: "Meest bekeken",
  REVIEW_SCORE: "Reviewscore",
  PRICE_ASCENDING: "Prijs oplopend",
  PRICE: "Prijs aflopend",
  DISCOUNT: "Prijsverschil",
};

const QUERY_NAME_MAP = new Map([
  ["merken", "brands"],
  ["sort", "orderBy"],
  ["direction", "sortDirection"],
  ["laagste_prijs_ooit", "lowestPriceUntilNow"],
  ["aanbieding", "discount"],
  ["winkels", "shops"],
  ["minprice", "minPrice"],
  ["maxprice", "maxPrice"],
  ["categorieën", "productCodes"],
  ["searchquery", "searchQuery"],
]);

const CompareListingFilters = ({
  filter,
  initialSlices,
}: CompareListingFiltersProps) => {
  const totalProducts = useStore($totalProducts);
  const isDesktop = useMedia("(min-width: 1024px)");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [filters, setFilters] = useState<
    Record<string, { id?: number; name: string }[]>
  >({});
  const [showMoreProductGroups, setShowMoreProductGroups] = useState(false);

  const [slices, setSlices] = useState(initialSlices);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const hash = decodeURI(url.hash);

    const filtersFromHash = hash.replace("#", "").split("|");

    const newFilters = filtersFromHash.reduce(
      (
        hashFilters: Record<string, { id?: number; name: string }[]>,
        string
      ) => {
        const [key, value] = string.split(":");
        if (!value) return hashFilters;

        const values = value.split(",");

        hashFilters[key] = values.map((name) => {
          const curRule = initialSlices
            .find((slice) => sanitizeFilter(slice.name) === key)
            ?.rules.find((rule) => sanitizeFilter(rule.name) === name);

          return {
            id: curRule?.sliceRuleId,
            name,
          };
        });

        return hashFilters;
      },
      {}
    );

    if ("brands" in filter)
      newFilters.merken = [
        ...(filter.brands as string[]).map((brand) => ({
          id: undefined,
          name: brand,
        })),
        ...(newFilters.merken || []),
      ];

    handleSubmit(newFilters);
    setFilters(newFilters);
  }, []);

  const updateUrl = (
    newFilters: Record<
      string,
      {
        id?: number | undefined;
        name: string;
      }[]
    >
  ) => {
    const url = new URL(window.location.href);
    let hash = "#";

    let pathname = (filter.slug as string) || url.pathname;

    Object.keys(newFilters).forEach((key) => {
      const values = newFilters[key];
      if (!values) return;

      const setHash = (hashKey: string, hashValues: string[] | undefined) => {
        if (!hashValues || hashValues.length === 0) return;

        const seperator = hash.includes(":") ? "|" : "";

        const stringValue = hashValues.join(",");
        if (stringValue.length === 0) return;

        hash = `${hash}${seperator}${hashKey}:${stringValue}`;
      };

      switch (key) {
        case "merken": {
          if (filter.slug) {
            pathname =
              values.length > 0 ? `${pathname}-${values[0].name}` : pathname;

            setHash(
              "merken",
              values.slice(1).map((value) => sanitizeFilter(value.name))
            );
          } else {
            setHash(
              "merken",
              values.map((value) => sanitizeFilter(value.name))
            );
          }
          break;
        }
        default:
          setHash(
            sanitizeFilter(key),
            values.map((value) => sanitizeFilter(value.name))
          );
      }
    });

    url.pathname = pathname;

    url.hash = hash.length > 1 ? hash : "";

    const search = new URLSearchParams(url.search);
    search.delete("page");

    url.search = search.toString();

    history.replaceState(null, document.title, url);
  };

  const onCheckedChange = (name: string, rule: SliceRule, checked: boolean) => {
    const newFilters = { ...filters };

    const key = sanitizeFilter(name);

    const sliceValues = newFilters[key];

    const ruleValue = {
      id: rule.sliceRuleId !== 0 ? rule.sliceRuleId : undefined,
      name: sanitizeFilter(rule.name),
    };

    if (checked) {
      newFilters[key] = sliceValues ? [...sliceValues, ruleValue] : [ruleValue];
    } else {
      newFilters[key] = sliceValues.filter((value) =>
        value.id
          ? value.id !== rule.sliceRuleId
          : value.name !== sanitizeFilter(rule.name)
      );
    }

    setFilters(newFilters);
    if (isDesktop) handleSubmit(newFilters);
  };

  const onValueChange = (name: string, value: string) => {
    const newFilters = { ...filters };

    const key = sanitizeFilter(name);
    newFilters[key] = [{ name: sanitizeFilter(value) }];

    setFilters(newFilters);

    if (isDesktop) handleSubmit(newFilters);
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFilters = { ...filters };
    const value = e.target.value;

    const direction = value.includes(SORT_DIRECTION.ascending)
      ? SORT_DIRECTION.ascending
      : SORT_DIRECTION.descending;

    newFilters.sort = [{ name: value.replace(`_${direction}`, "") }];
    newFilters.direction = [{ name: direction }];

    setFilters(newFilters);
    if (isDesktop) handleSubmit(newFilters);
  };

  const handleSubmit = async (
    newFilters: Record<string, { id?: number; name: string }[]>
  ) => {
    const mappedFilters = Object.keys(newFilters).reduce(
      (obj: Record<string, string[]>, key) => {
        const keyName = QUERY_NAME_MAP.get(key) || "sliceRules";
        const values = newFilters[key].map((value) => {
          if (value.name === "ja") return "true";

          return value.id && value.id !== 0 ? value.id?.toString() : value.name;
        });

        if (keyName in obj) {
          obj[keyName] = [...obj[keyName], ...values];
        } else {
          obj[keyName] = values;
        }

        return obj;
      },
      {}
    );

    const newSlices = await slicesResolver.list({
      ...filter,
      ...mappedFilters,
    });

    setStoreFilters(mappedFilters);
    setSlices(newSlices);
    updateUrl(newFilters);

    if (inputRef.current) inputRef.current.checked = false;
  };

  const handleRemoveFilter = (
    name: string,
    rule: { id?: number; name: string }
  ) => {
    const newFilters = { ...filters };
    const key = sanitizeFilter(name);

    const sliceValues = newFilters[key];

    newFilters[key] = sliceValues.filter((value) =>
      value.id ? value.id !== rule.id : value.name !== sanitizeFilter(rule.name)
    );

    setFilters(newFilters);
    handleSubmit(newFilters);
  };

  const handleReset = (name: string) => {
    const newFilters = { ...filters };
    const key = sanitizeFilter(name);

    delete newFilters[key];

    setFilters(newFilters);
    handleSubmit(newFilters);
  };

  const productGroups = slices
    .find((slice) => slice.name === "Categorieën")
    ?.rules.map((rule) => rule.displayName || "");

  const handleOpenFilter = (open: boolean) => {
    setShowFilter(open);
    document.body.classList.toggle("overflow-y-hidden");
  };

  const handleConfirmFilters = async () => {
    await handleSubmit(filters);
    handleOpenFilter(false);
  };

  return (
    <div>
      {productGroups !== undefined && (
        <div className="hidden lg:block bg-muted mb-6">
          <div className="text-lg bg-tertiary text-tertiary-foreground p-2.5 flex justify-between items-end">
            Productgroepen
            <button
              type="button"
              className={cn(
                buttonVariants({ variant: "link" }),
                "hidden text-xs justify-start w-fit py-2.5 px-0 lg:py-0 h-fit text-tertiary-foreground",
                { flex: showMoreProductGroups }
              )}
              onClick={() => setShowMoreProductGroups((prev) => !prev)}
            >
              <Icon icon="minus" size={8} />
              <span>Toon minder</span>
            </button>
          </div>

          <ul className="p-2.5 gap-1 flex flex-col">
            {productGroups
              .splice(0, showMoreProductGroups ? 16 : 4)
              .map((group) => (
                <li key={group}>
                  <a
                    href={`/${sanitizeCategorySlug(group)}`}
                    className="text-foreground"
                  >
                    {toUppercase(group)}
                  </a>
                </li>
              ))}

            <li>
              <button
                type="button"
                className={cn(
                  buttonVariants({ variant: "link" }),
                  "text-xs justify-start w-fit flex py-2.5 px-0 lg:py-0 h-fit"
                )}
                onClick={() => setShowMoreProductGroups((prev) => !prev)}
              >
                <Icon
                  icon={showMoreProductGroups ? "minus" : "plus"}
                  size={8}
                />
                <span>Toon {showMoreProductGroups ? "minder" : "meer"}</span>
              </button>
            </li>
          </ul>
        </div>
      )}

      <div
        className={cn(
          "bg-page py-2.5 flex justify-between px-2.5 gap-5 mb-2.5",
          "lg:flex-col-reverse lg:bg-muted lg:px-0 lg:pt-0 lg:mb-0"
        )}
      >
        <Button
          className="font-medium justify-between flex-1 lg:hidden"
          fullWidth
          onClick={() => handleOpenFilter(true)}
        >
          Filteren ({totalProducts}) <Icon icon="filter" size={16} />
        </Button>

        <div
          className={cn(
            "fixed left-0 top-0 w-screen h-screen hidden bg-nav text-nav-foreground flex-col z-50 lg:z-0 text-sm",
            "lg:flex lg:relative lg:w-full lg:h-auto lg:bg-transparent lg:text-inherit",
            { flex: showFilter }
          )}
        >
          <button
            type="button"
            className={cn(
              "min-h-[60px] px-5 flex items-center bg-muted text-muted-foreground justify-between",
              "lg:hidden"
            )}
            onClick={() => handleOpenFilter(false)}
          >
            <div className="flex gap-1 items-center text-sm">
              <Icon icon="filter" size={16} />
              Filteren ({totalProducts} resultaten)
            </div>

            <Icon icon="close" size={16} />
          </button>

          <div className="w-full grid grid-cols-1 overflow-y-scroll lg:overflow-y-hidden pb-20 lg:pb-0">
            <ListingFilterItem
              name="Prijs (in Euro)"
              onValueChange={onValueChange}
              filters={[
                filters.minprice ? filters.minprice[0] : { name: "" },
                filters.maxprice ? filters.maxprice[0] : { name: "" },
              ]}
              defaultOpen
            />

            <ListingFilterItem
              name="Verfijn resultaten"
              onValueChange={onValueChange}
              filters={[
                filters.searchquery ? filters.searchquery[0] : { name: "" },
              ]}
              defaultOpen
            />

            {slices.map((slice, index) => (
              <ListingFilterItem
                key={`${slice.name}_${slice.id}_${slice.rules.length}`}
                onCheckChange={onCheckedChange}
                onReset={handleReset}
                filters={filters[sanitizeFilter(slice.name)] || []}
                {...slice}
                rules={slice.rules}
                defaultOpen={index <= 5}
                className={cn({
                  "row-start-1": slice.name === "Aanbieding",
                })}
              />
            ))}
          </div>

          <div
            className={cn(
              "absolute left-0 bottom-0 w-full bg-nav-background p-2.5 border-t",
              "lg:hidden"
            )}
          >
            <div
              className={cn(
                "min-h-[60px] px-5 flex items-center bg-muted text-muted-foreground justify-between",
                "lg:hidden"
              )}
            >
              <div className="flex gap-1 items-center text-sm">
                <Icon icon="filter" size={16} />
                Filteren ({totalProducts} resultaten)
              </div>

              <Icon icon="close" size={16} />
            </div>

            <div
              className={cn(
                "absolute left-0 bottom-0 w-full bg-nav-background p-2.5 border-t",
                "lg:hidden"
              )}
            >
              <Button onClick={handleConfirmFilters} fullWidth>
                Filters toepassen
              </Button>
            </div>
          </div>
        </div>

        <select
          className={cn(
            "relative flex-1 bg-white border text-sm rounded px-2.5",
            "lg:mx-2.5 lg:py-2.5 lg:text-link"
          )}
          onChange={handleSortChange}
          aria-label="Sorteer op"
        >
          {Object.keys(SORT_MAP).map((key) => (
            <option key={key} value={key}>
              {`Sorteer op: ${SORT_MAP[key]}`}
            </option>
          ))}
        </select>

        <div className="hidden lg:block text-lg bg-tertiary text-tertiary-foreground p-2.5">
          Filter {totalProducts} resultaten
        </div>
      </div>
    </div>
  );
};

export { CompareListingFilters };
