import type { SliceRule } from "api/slices/types";
import { Checkbox } from "../atoms/checkbox";
import { cn, sanitizeFilter, toUppercase } from "../../utils";

interface ListingFilterItemCheckboxProps {
  filterName: string;
  rule: SliceRule;
  onChange: (name: string, rule: SliceRule, checked: boolean) => void;
  isChecked: boolean;
  groupChecked: boolean;
}

const ListingFilterItemCheckbox = ({
  rule,
  filterName,
  onChange,
  isChecked,
  groupChecked,
}: ListingFilterItemCheckboxProps) => {
  const amount = !groupChecked ? rule.amount : undefined;

  return (
    <li
      key={rule.name}
      className={cn("bg-white p-2.5", "lg:bg-transparent lg:p-0")}
    >
      <Checkbox
        id={sanitizeFilter(`${filterName}_${rule.name}`)}
        label={toUppercase(rule.displayName || rule.name)}
        amount={amount}
        aria-label={rule.name}
        onCheckedChange={(checked) =>
          onChange(filterName, rule, Boolean(checked))
        }
        checked={isChecked}
      />
    </li>
  );
};

export { ListingFilterItemCheckbox };
